<template>
	<div>
		<v-banner v-if="uiFlags.configReady" two-line>
			<v-avatar slot="icon" color="primary">
				<v-icon icon="mdi-lock" dark>mdi-magnify-expand</v-icon>
			</v-avatar>
			<div>{{ getToolkit.name }}</div>
			<div class="text-caption">{{ getToolkit.desc }}</div>
			<template v-slot:actions>
				<div class="d-flex flex-column">
					<v-chip
						class="pill-width"
						:color="uiFlags.apiStatus == 'success' ? 'green' : 'red'"
						outlined
						pill
						small
						close
						close-icon="mdi-information"
						@click:close="getAPIInfo()"
					>
						<v-icon x-small left> mdi-server </v-icon>
						API Health: {{ uiFlags.apiStatus | status }}
					</v-chip>
					<v-chip class="mt-1 pill-width" color="primary" outlined pill small>
						<v-icon small left> mdi-folder-outline </v-icon>
						{{ getToolkit.project.name }}
					</v-chip>
				</div>
			</template>
		</v-banner>
		<div v-if="this.uiFlags.apiStatus == 'error'">
			<v-alert class="ma-5" text prominent type="error" icon="mdi-cloud-alert">
				<v-row align="center">
					<v-col class="grow">
						OneML is not able to connect or check health of the API for
						<b>{{ getToolkit.name }}</b
						>. Please check with ML/development team for more details or try
						again later.
					</v-col>
					<v-col class="shrink">
						<v-btn
							small
							color="red"
							:loading="uiFlags.apiStatus == 'checking'"
							@click="recheckAPIHealth()"
						>
							Retry
							<v-icon right dark>mdi-refresh</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-alert>
		</div>
		<div v-else-if="this.uiFlags.apiStatus == 'success'">
			<div class="toolkit-container">
				<div v-if="uiFlags.isFirstSearch">
					<div class="full-screen-search-input-area">
						<div class="full-screen-search-input">
							<div class="toolkit-title">
								{{ getToolkit.name }}
							</div>
							<v-text-field
								v-model="searchData.query"
								label="Search Query"
								required
								clearable
								filled
								class=""
								:placeholder="getSearchkitConfig.placeholder_msg"
								persistent-placeholder
								:loading="loading"
								@keyup.enter="search()"
							></v-text-field>
							<div>
								<v-row v-if="metadata">
									<v-col>
										<v-card>
											<v-card-text>
												<div class="text-h2">{{ metadata.nModes }}</div>
												<div>Modes</div>
											</v-card-text>
										</v-card>
									</v-col>
									<v-col>
										<v-card>
											<v-card-text>
												<div class="text-h2">{{ metadata.nFilters }}</div>
												<div>Filters</div>
											</v-card-text>
										</v-card>
									</v-col>
									<v-col>
										<v-card>
											<v-card-text>
												<div class="text-h2">{{ metadata.api_version }}</div>
												<div>API Version</div>
											</v-card-text>
										</v-card>
									</v-col>
								</v-row>
								<v-alert
									border="right"
									colored-border
									type="error"
									elevation="2"
									v-if="!canGiveFeedbackInToolkits"
									class="mt-4"
								>
									Based on your role in OneML, your feedbacks have been disabled
									for this toolkit.
								</v-alert>
							</div>
						</div>
					</div>
				</div>
				<!-- Main/Static search area -->
				<div v-if="!uiFlags.isFirstSearch" class="static-input-area">
					<v-text-field
						type="text"
						v-model="searchData.query"
						label="Search Query"
						:hint="getSearchkitConfig.placeholder_msg"
						required
						clearable
						solo
						@keyup.enter="search()"
						:loading="loading"
					>
						<template v-slot:append>
							<v-btn icon @click="search()" color="primary"
								><v-icon>mdi-magnify</v-icon></v-btn
							>
							<v-btn
								icon
								color="indigo"
								@click="uiFlags.showFilterPane = !uiFlags.showFilterPane"
							>
								<v-icon v-if="uiFlags.showFilterPane"
									>mdi-filter-outline</v-icon
								>
								<v-icon v-else>mdi-filter-off-outline</v-icon>
							</v-btn>
							<v-btn icon color="red" @click="openAnalytics()"
								><v-icon>mdi-google-analytics</v-icon></v-btn
							>
						</template>
						<template
							v-slot:append-outer
							v-if="uiFlags.isMetadataReady && metadata.nModes > 0"
						>
							<v-select
								solo
								label="Select Mode"
								:items="metadata.modes"
								item-text="name"
								item-value="value"
								v-model="searchData.mode"
								@change="modeChanged()"
							>
							</v-select>
						</template>
					</v-text-field>
				</div>
				<div class="d-flex flex-row" v-if="!uiFlags.isFirstSearch">
					<!-- filters -->
					<v-card
						class="filters-card"
						v-if="uiFlags.isMetadataReady && uiFlags.showFilterPane"
					>
						<v-toolbar flat dense>
							<div>Filter results</div>
							<v-spacer></v-spacer>
							<v-btn
								icon
								@click="uiFlags.showFilterPane = !uiFlags.showFilterPane"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-toolbar>
						<v-card-text v-if="metadata.nFilters > 0">
							<ToolkitFilter
								v-bind:filter="filter"
								v-bind:key="index"
								v-for="(filter, index) in metadata.filters"
								@filter-changed="filterChanged"
							/>
							<v-btn
								v-if="uiFlags.filtersChanged"
								color="success"
								small
								class="mt-2"
								:loading="loading"
								@click="search()"
								>Apply Filters
								<v-icon right dark>mdi-filter-check-outline</v-icon>
							</v-btn>
						</v-card-text>
						<v-card-text v-else>
							Seems like there are no filters available for this toolkit.
						</v-card-text>
					</v-card>
					<!-- results -->
					<div class="result-container" v-if="searchResult">
						<v-chip-group v-if="searchResult.tags">
							<v-chip v-for="tag in searchResult.tags" :key="tag.value">
								{{ tag.value }}
							</v-chip>
						</v-chip-group>
						<v-alert
							v-model="uiFlags.showGenerativeResult"
							dismissible
							color="success"
							border="left"
							elevation="2"
							colored-border
							icon="mdi-auto-fix"
							class="generative-result mb-3"
							:loading="true"
						>
							<div class="generative-title">Result Summary</div>
							<div
								v-if="generativeResult.htmlText != null"
								class="generative-html"
								v-html="generativeResult.htmlText"
							></div>
							<div v-else class="generative-text">
								{{ generativeResult.plainText }}
							</div>
							<div class="feedback-panel" v-if="canGiveFeedbackInToolkits">
								<div
									class="feedback-group"
									v-if="!uiFlags.loadingGenerativeFeedback"
								>
									<v-btn
										small
										text
										@click="uiFlags.getGenerativeFeedback = true"
										>Exemplify<v-icon right>mdi-draw-pen</v-icon></v-btn
									>
								</div>
								<div v-else>
									<span class="text-caption">Feedback submitted</span>
								</div>
							</div>
						</v-alert>
						<v-data-iterator
							:items="searchResult.results"
							:server-items-length="searchResult.count"
							:options.sync="tableOptions"
						>
							<template v-slot:default="props">
								<SearchResult
									v-for="resultItem in props.items"
									v-bind:resultItem="resultItem"
									v-bind:cardLoader="
										documentToView.id == resultItem.record_id ||
										feedbackSubmissionId == resultItem.record_id
									"
									v-bind:cachedObj="resultItem.cachedObj"
									:key="resultItem.key"
									@open-document="openDocument"
									@feedback-event="submitSearchFeedback"
								/>
							</template>
						</v-data-iterator>
					</div>
				</div>
			</div>
		</div>
		<DocumentView v-bind:document="documentToView.obj" />
		<ExemplifiedFeedback
			v-bind:showDialog="uiFlags.getGenerativeFeedback"
			v-bind:title="'Feedback for Generative Result'"
			v-bind:systemText="generativeResult.plainText"
			v-bind:systemHtml="generativeResult.htmlText"
			@closed="uiFlags.getGenerativeFeedback = false"
			@submit-feedback="submitGenerativeFeedback"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ToolkitFilter from "@/components/toolkits/controls/ToolkitFilter.vue";
import SearchResult from "@/components/toolkits/controls/SearchResult.vue";
import DocumentView from "@/components/toolkits/controls/DocumentView.vue";
import ExemplifiedFeedback from "@/components/toolkits/controls/ExemplifiedFeedback.vue";
import { compareObjects } from "@/store/common/utils";
import CacheWithExpiry from "@/store/toolkit/cache";
import { joinUrls, hashQuery, generateRandomKey } from "@/store/common/utils";

export default {
	name: "SearchToolkit",
	computed: {
		...mapGetters([
			"getSearchkitConfig",
			"getToolkit",
			"getFeedbackCache",
			"canGiveFeedbackInToolkits",
		]),
	},
	components: {
		ToolkitFilter,
		SearchResult,
		DocumentView,
		ExemplifiedFeedback,
	},
	data: () => ({
		loading: false,
		uiFlags: {
			configReady: false,
			apiStatus: "checking",
			isFirstSearch: true,
			isMetadataReady: false,
			showFilterPane: true,
			filtersChanged: false,
			showGenerativeResult: false,
			loadingGenerativeFeedback: false,
			getGenerativeFeedback: false,
		},
		searchData: {
			mode: null,
			query: "",
			filters: {},
			offset: 0,
			limit: 10,
		},
		tableOptions: {
			init: true,
			itemsPerPage: 10,
		},
		documentToView: {
			id: null,
			obj: null,
		},
		feedbackSubmissionId: null,
		metadata: null,
		searchResult: null,
		cache: null,
		cacheIndentification: {
			lastSearchData: null,
			cacheId: null,
			keysToIgnore: new Set(["offset", "limit"]),
		},
		generativeResult: {
			plainText: null,
			htmlText: null,
		},
	}),
	watch: {
		tableOptions: {
			handler: async function (oldVal, newOptions) {
				if (!this.tableOptions.init) {
					await this.search();
				}
				this.tableOptions.init = false;
			},
		},
	},
	async created() {
		// this.$store.commit("hideDrawer");
		// `search_cache_${this.getToolkit.id}`
		// this.cache = new CacheWithExpiry(30 * 60); // 30 minute cache
		await this.fetchToolkit();
		await this.fetchSearchConfig();
		this.uiFlags.configReady = true;
		this.checkAPIHealth();
		// start execution for health check with 1 sec delay using await
		await this.getAPIMetadata();
	},
	async mounted() {
		const ttlInSeconds = 6 * 60 * 60; // 6 hours
		// check if cache exists in local storage
		const cacheJSON =
			localStorage.getItem(`search_cache_${this.$route.params.id}`) || null;
		if (cacheJSON != null) {
			this.cache = CacheWithExpiry.fromJSON(cacheJSON, ttlInSeconds);
		} else {
			this.cache = new CacheWithExpiry(ttlInSeconds); // 30 minute cache
		}
	},
	methods: {
		showNotification(msg) {
			this.$store.commit("showNotification", msg);
		},
		setMetadata(metadataObj) {
			if (metadataObj == null) return;
			this.metadata = metadataObj;
			this.metadata.nFilters =
				this.metadata.filters == null ? 0 : this.metadata.filters.length;
			this.metadata.nModes =
				this.metadata.modes == null ? 0 : this.metadata.modes.length;
			this.uiFlags.isMetadataReady = true;
			this.uiFlags.showFilterPane = this.metadata.nFilters > 0;
			this.searchData.filters = {};
			this.searchData.mode = this.metadata.default_mode;
			if (this.metadata.nFilters > 0) {
				for (let i = 0; i < this.metadata.filters.length; i++) {
					let filter = this.metadata.filters[i];
					this.searchData.filters[filter.name] = null;
					if (filter.multi_select) {
						this.searchData.filters[filter.name] = filter.pre_selected_value;
					} else {
						this.searchData.filters[filter.name] = filter.pre_selected_value;
					}
				}
			}
		},
		resetPagination() {
			this.tableOptions.init = true;
			this.tableOptions.page = 1;
			this.tableOptions.itemsPerPage = 10;
		},
		async fetchToolkit() {
			await this.$store.dispatch("fetchToolkit", this.$route.params.id);
		},
		async fetchSearchConfig() {
			await this.$store.dispatch(
				"fetchSearchkitByToolkitId",
				this.$route.params.id
			);
		},
		async checkAPIHealth() {
			// console.log('checking api health');
			try {
				this.uiFlags.apiStatus = "checking";
				// const url = new URL(this.getSearchkitConfig.health_endpoint, this.getSearchkitConfig.hostname);
				const response = await axios.get(
					joinUrls(
						this.getSearchkitConfig.hostname,
						this.getSearchkitConfig.health_endpoint
					)
				);
				if (response.status == 200) {
					this.uiFlags.apiStatus = "success";
				} else {
					this.uiFlags.apiStatus = "error";
				}
			} catch (error) {
				console.log(error);
				this.uiFlags.apiStatus = "error";
			}
		},
		async recheckAPIHealth() {
			await this.checkAPIHealth();
			this.uiFlags.isFirstSearch = true;
			await this.getAPIMetadata();
		},
		async genericAPICall(endpoint, method = "get", data = null) {
			let flag200Ok = false;
			let response = null;
			try {
				const url = joinUrls(this.getSearchkitConfig.hostname, endpoint);
				const headers = {
					headers: {
						Authorization: "token " + this.getSearchkitConfig.api_key,
					},
				};
				if (method == "get") response = await axios.get(url, headers);
				else if (method == "post")
					response = await axios.post(url, data, headers);
				else throw new Error(`Method ${method} not supported.`);
				flag200Ok = response.status == 200;
			} catch (error) {
				console.log(error);
				flag200Ok = false;
			}
			if (flag200Ok) return response.data;
			else {
				this.uiFlags.apiStatus = "error";
				this.showNotification("Oops! Something went wrong.");
				return null;
			}
		},
		async getAPIMetadata() {
			this.setMetadata(
				await this.genericAPICall(this.getSearchkitConfig.metadata_endpoint)
			);
		},
		async filterChanged(filterObj) {
			this.searchData.filters[filterObj.name] = filterObj.value;
			// console.log(`Filter ${filterObj.name} changed to ${filterObj.value}`);
			if (filterObj.postback) {
				await this.search();
			} else {
				this.uiFlags.filtersChanged = true;
			}

			// reset pagination when filters are changed
		},
		async paginationEvent() {
			if (this.paginationObj.currentPage == this.paginationObj.page) return;
			await this.search();
		},
		async modeChanged() {
			console.log("mode changed");
			await this.searchWrapper();
			// reset pagination when mode is changed
		},
		async searchWrapper() {
			this.loading = true;
			setTimeout(async () => await this.search(), 500);
		},
		async search() {
			try {
				let query_changed = false;
				this.loading = true;
				/*
        based last search data, check if query has changed.
        If this is the first query or query has changed, then set query_changed = true
        */
				if (
					this.cacheIndentification.lastSearchData == null ||
					(this.cacheIndentification.lastSearchData != null &&
						this.cacheIndentification.lastSearchData.query !=
							this.searchData.query)
				) {
					query_changed = true;
					this.cacheIndentification.cacheId = hashQuery(this.searchData.query);
				}
				// check if search data is same as last search except offset & limit
				if (
					this.cacheIndentification.lastSearchData == null ||
					(this.cacheIndentification.lastSearchData != null &&
						!compareObjects(
							this.cacheIndentification.lastSearchData,
							this.searchData,
							this.cacheIndentification.keysToIgnore
						))
				) {
					this.cacheIndentification.lastSearchData = structuredClone(
						this.searchData
					);
					this.resetPagination();
				}
				// calculate offset and limit based on pagination.page & pagination.nPages
				this.searchData.offset = isNaN(this.tableOptions.page)
					? 0
					: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
				this.searchData.limit = isNaN(this.tableOptions.itemsPerPage)
					? 10
					: this.tableOptions.itemsPerPage;
				// calculate number of words in query
				const nWords = this.searchData.query.split(" ").length;
				if (nWords < this.getSearchkitConfig.min_query_words) {
					this.showNotification(
						`Please enter at least ${this.getSearchkitConfig.min_query_words} words in search query.`
					);
					return;
				} else if (nWords > this.getSearchkitConfig.max_query_words) {
					this.showNotification(
						`Please enter at most ${this.getSearchkitConfig.max_query_words} words in search query.`
					);
					return;
				}
				// check if mode is selected
				if (this.metadata.nModes > 0 && this.searchData.mode == null) {
					this.showNotification(`Please select a mode.`);
					return;
				}
				// create a structured clone of search data & set query_changed
				const searchDataCopy = structuredClone(this.searchData);
				searchDataCopy.query_changed = query_changed;
				// console.log(searchDataCopy);
				console.log("search call to sandbox triggered");
				const tempResult = await this.genericAPICall(
					this.getSearchkitConfig.search_endpoint,
					"post",
					searchDataCopy
				);
				// Iterate over results & check if feedback is already submitted for any of the record
				if (this.cache.size() > 0) {
					for (let i = 0; i < tempResult.results.length; i++) {
						const resultItem = tempResult.results[i];
						const recordId = resultItem.record_id.substring(0, 150);
						const feedbackCacheId = `${this.cacheIndentification.cacheId}_${recordId}`;
						if (this.cache.has(feedbackCacheId)) {
							console.log(`Cache found for ${recordId}`);
							const cachedObj = this.cache.get(feedbackCacheId);
							if (cachedObj["freshness"] == undefined)
								cachedObj["freshness"] = false;
							if (cachedObj["relevance"] == undefined)
								cachedObj["relevance"] = false;
							resultItem.cachedObj = cachedObj;
						}
						resultItem.key = generateRandomKey();
					}
				}
				this.searchResult = tempResult;
				// this.searchResult.metadata is not null of nan then update filters
				if (this.searchResult.metadata != null) {
					this.setMetadata(this.searchResult.metadata);
				}
				this.uiFlags.showGenerativeResult =
					this.searchResult.generative_result != null;
				if (this.uiFlags.showGenerativeResult) {
					this.generativeResult.plainText =
						this.searchResult.generative_result.plain_text;
					this.generativeResult.htmlText =
						this.searchResult.generative_result.html_text;
				}
			} finally {
				this.uiFlags.filtersChanged = false;
				this.loading = false;
				// purposefully added a if check to avoid ui flickering
				if (this.uiFlags.isFirstSearch) this.uiFlags.isFirstSearch = false;
				this.uiFlags.loadingGenerativeFeedback = false;
				// scroll to the top of the page
				window.scrollTo(0, 0);
			}
		},
		async delayedOpenDocument(resultItem) {
			this.documentToView.id = resultItem.record_id;
			setTimeout(() => {
				this.openDocument(resultItem);
			}, 500);
		},
		async openDocument(resultItem) {
			this.documentToView.id = resultItem.record_id;
			try {
				this.documentToView.obj = await this.genericAPICall(
					this.getSearchkitConfig.document_endpoint,
					"post",
					{ record_id: resultItem.record_id }
				);
			} finally {
				this.documentToView.id = null;
			}
		},
		addFeedbackToCache(feedbackObj) {
			if (this.cacheIndentification.cacheId == null) return;
			// ensure we dont take more than first 20 chars from record_id
			const recordId = feedbackObj.document.record_id.substring(0, 150);
			// cache key format: cacheId_recordId
			const feedbackCacheId = `${this.cacheIndentification.cacheId}_${recordId}`;
			const nDeleted = this.cache.cleanupExpired();
			if (nDeleted > 0)
				console.log(`Deleted ${nDeleted} expired feedbacks from cache.`);
			// check if this record already exists in the cache
			let cachedObj = {};
			if (this.cache.has(feedbackCacheId)) {
				cachedObj = this.cache.get(feedbackCacheId);
			}
			cachedObj[feedbackObj.type] = true;
			this.cache.set(feedbackCacheId, cachedObj);
			// store in local storage
			localStorage.setItem(
				`search_cache_${this.getToolkit.id}`,
				this.cache.toJSON()
			);
		},
		async submitSearchFeedback(feedbackObj) {
			this.feedbackSubmissionId = feedbackObj.document.record_id;
			try {
				await this.$store.dispatch("submitSearchFeedback", {
					toolkitId: this.getToolkit.uri.id,
					query: this.searchData.query,
					record_rank: feedbackObj.document.rank,
					record_id: feedbackObj.document.record_id,
					feedback_type: feedbackObj.type,
					feedback_value: feedbackObj.value,
					score: feedbackObj.document.score,
					mode: this.metadata.nModes > 0 ? this.searchData.mode : null,
					filters: this.searchData.filters,
				});
				this.showNotification("Feedback submitted successfully.");
				this.addFeedbackToCache(feedbackObj);
			} finally {
				this.feedbackSubmissionId = null;
			}
		},
		async submitGenerativeFeedback(feedbackObj) {
			try {
				this.uiFlags.loadingGenerativeFeedback = true;
				const filters = structuredClone(this.searchData.filters);
				filters["mode"] = this.searchData.mode;
				await this.$store.dispatch("submitGenAIFeedback", {
					toolkitId: this.getToolkit.uri.id,
					query: this.searchData.query,
					feedback_value: feedbackObj.value,
					generated_text: feedbackObj.systemText,
					user_text: feedbackObj.userText,
					filters: filters,
				});
				this.showNotification("Feedback submitted successfully.");
				this.uiFlags.getGenerativeFeedback = false;
			} finally {
				this.uiFlags.loadingGenerativeFeedback = false;
			}
		},
		openAnalytics() {
			const routeData = this.$router.resolve({
				name: "search-analytics",
				params: { id: this.getToolkit.uri.id },
			});
			window.open(routeData.href, "_blank");
		},
	},
};
</script>

<style>
.pill-width {
	width: fit-content;
}
</style>
